import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import availableLangs from '@constants/availableLangs'
import usePageContext from '@hooks/usePageContext'
import NewsArticlePageContainer from '@containers/NewsArticlePageContainer'

const IndexPage = () => {
  const { pageContext } = usePageContext()

  const newsSubPage = pageContext['*']

  useEffect(() => {
    // if user navigates to /news without subpage => redirect to main page
    if (!!window && newsSubPage === '') {
      let currentLang = window.navigator.language
      if (currentLang.includes('-')) {
        currentLang = currentLang.split('-')[0]
      }
      navigate(`/${availableLangs[currentLang] || availableLangs.en}`)
    }
  }, [])

  return newsSubPage ? <NewsArticlePageContainer /> : <></>
}

export default IndexPage
