import React, { memo } from 'react'
import WeCreateLayout from '@layouts/WeCreateLayout'

import CookieConsent from '@common/CookieConsent'
import PageScrollHorizontalProgressBar from '@common/PageScrollHorizontalProgressBar'
// import ContactUsContainer from '@containers/ContactUsContainer'
import WeCreatePageSeoContainer from '@containers/WeCreatePageSeoContainer'
import { NewsSingleArticleContainer } from '@containers/weCreateBrand'
import LoadingSpinner from '@common/spinners/LoadingSpinner'
import NewsSlider from '@common/sliders/NewsSlider'

import useNewsContext from '@hooks/useNewsContext'
import usePageContext from '@hooks/usePageContext'
import availableLangs from '@constants/availableLangs'
import { constructNeverchangeParam } from '@utils'
import { MaxWidthWrapper } from '@common/CommonStyles'

const NewsArticlePageContainer = () => {
  const { isNewsPage, pageContext } = usePageContext()
  const { langKey } = pageContext
  const newsSubPage = pageContext['*']

  const { openedNewsItem, selectOpenedNewsItem, isLoading, newsList, isError, loadNews, canLoadMore } = useNewsContext()
  const { seoKeywords = '' } = openedNewsItem || {}

  const neverchangeLinkParam = constructNeverchangeParam(availableLangs[langKey] || availableLangs.de)
  const listToRender = newsList.filter(item => item[neverchangeLinkParam] !== newsSubPage)
  const renderSlider = !!newsSubPage && !!listToRender.length

  const loadNewsToSlider = async () => {
    return await loadNews({ withLoading: false })
  }

  return (
    <>
      <WeCreatePageSeoContainer seoKeywords={seoKeywords} />
      <WeCreateLayout>
        <PageScrollHorizontalProgressBar />
        <MaxWidthWrapper>
          <NewsSingleArticleContainer newsItem={openedNewsItem} isError={isError} />

          {renderSlider && (
            <NewsSlider
              newsList={listToRender}
              selectOpenedNewsItem={selectOpenedNewsItem}
              isNewsPage={isNewsPage}
              loadNewsToSlider={loadNewsToSlider}
              canLoadMore={canLoadMore}
            />
          )}
        </MaxWidthWrapper>

        {isLoading && <LoadingSpinner />}
      </WeCreateLayout>

      {/* USE COOKIES */}
      <CookieConsent />

      {/* CONTACT US (button+modal) */}
      {/* <ContactUsContainer /> */}
    </>
  )
}

export default memo(NewsArticlePageContainer)
